import type { ProfileWithIncomingInvitations } from 'operations/ProfileWithIncomingInvitations'

import { gql, useQuery } from '@apollo/client'
import { FunctionComponent, useState } from 'react'
import { whether } from 'utils'

import { Box, Text } from 'components/ui'

import InvitationCard from '../common/invitation_card'

const Q_PROFILE_BY_PK = gql`
  query ProfileWithIncomingInvitations($profileId: Int!) {
    profiles_by_pk(id: $profileId) {
      first_name
      last_name
      id
      username
      availability
      created_at
      services
      invitations_received(
        where: {
          _and: [
            { accepted_at: { _is_null: true } }
            { ignored_at: { _is_null: true } }
          ]
        }
      ) {
        accepted_at
        created_at
        from_profile_id
        id
        from_profile {
          first_name
          username
          last_name
          current_job_new
          experiences
          id
          avatar
        }
      }
    }
  }
`

interface ListOfProfile {
  name: string
  mutual_connection: string
  invitationId: number
}
interface PeopleToAcceptProps {
  toProfileId: number
}
const PeopleToAccept: FunctionComponent<PeopleToAcceptProps> = ({
  toProfileId,
}) => {
  const [listOfProfiles, setListOfProfiles] = useState<ListOfProfile[]>([])
  const { loading, error, data, refetch, updateQuery } =
    useQuery<ProfileWithIncomingInvitations>(Q_PROFILE_BY_PK, {
      variables: { profileId: toProfileId },
    })

  if (loading && !data) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error loading items: {JSON.stringify(error)}</div>
  }

  const removeIncomingInvitation = (invitationId: number) => {
    let currentProfiles: ProfileWithIncomingInvitations
    currentProfiles = JSON.parse(JSON.stringify(data))
    let invitations = currentProfiles?.profiles_by_pk?.invitations_received
    if (invitations && currentProfiles?.profiles_by_pk) {
      invitations = invitations.filter((invitation) => {
        invitation.id != invitationId
      })
      currentProfiles.profiles_by_pk.invitations_received = invitations
    }
    //Only updating query cache data, no request to server
    updateQuery((Q_PROFILE_BY_PK) => ({
      profiles_by_pk: currentProfiles.profiles_by_pk,
    }))
  }

  return whether(
    data?.profiles_by_pk?.invitations_received?.length || 0,
    <section>
      <Box title="Invitations">
        {data?.profiles_by_pk?.invitations_received.map((invitation, index) => (
          <InvitationCard
            username={invitation.from_profile.username ?? ''}
            key={index}
            name={{
              first_name: invitation.from_profile.first_name,
              last_name: invitation.from_profile.last_name,
            }}
            currentJob={{
              experiences: invitation.from_profile.experiences,
              currentJob: invitation.from_profile.current_job_new,
            }}
            profile={invitation.from_profile}
            userId={invitation?.from_profile?.id}
            invitationId={invitation.id}
            onClickOption={removeIncomingInvitation}
            onCompletedAction={refetch}
            type="people_to_accept_invitation"
          />
        ))}
      </Box>
    </section>,
  )
}

export default PeopleToAccept
