import type { InvitationAccept } from 'operations/InvitationAccept'
import { UpdateInvitationById } from 'operations/UpdateInvitationById'

import {
  M_INVITATION_ACCEPT,
  M_UPDATE_INVITATION_BY_ID,
} from 'lib/mutations/invitations'

import { useMutation } from '@apollo/client'
import clsx from 'clsx'
import Link from 'next/link'
import { FunctionComponent } from 'react'

import useMutualConnections from 'components/hooks/useMutualConnections'
import { Experience } from 'components/profile/profile.interface'
import { Avatar } from 'components/ui'
import { AvatarProps } from 'components/ui/avatar'

import CurrentJob from './current_job'

interface InvitationCardProps {
  invitationId: number
  name: { first_name: string; last_name: string }
  currentJob: {
    experiences?: Experience[]
    currentJob?: string | null
  }
  onCompletedAction:
    | undefined
    | ((data: InvitationAccept | UpdateInvitationById) => void)
  onClickOption?: (invitationId: number) => void
  profile: AvatarProps['profile']
  userId: number | undefined
  username: string | undefined
  type: string
}

const InvitationCard: FunctionComponent<InvitationCardProps> = ({
  invitationId,
  name,
  currentJob,
  onCompletedAction,
  onClickOption,
  profile,
  userId,
  username,
  type,
}) => {
  const { mutualConnections } = useMutualConnections(userId!)
  const [invitationAccept] = useMutation<InvitationAccept>(
    M_INVITATION_ACCEPT,
    {
      onCompleted: onCompletedAction,
    },
  )

  const [ignoreInvitation] = useMutation<UpdateInvitationById>(
    M_UPDATE_INVITATION_BY_ID,
    {
      onCompleted: onCompletedAction,
    },
  )
  let classes = { mainDiv: '', currentJobContainer: 'flex-initial w-4/6' }

  if (type === 'pending_connections') {
    classes = {
      mainDiv: 'w-full',
      currentJobContainer: '',
    }
  }

  const accept = () => {
    if (onClickOption) {
      onClickOption(invitationId)
    }

    invitationAccept({ variables: { invitationId } })
  }

  const ignore = () => {
    if (onClickOption) {
      onClickOption(invitationId)
    }
    const today = new Date()
    const ignoreDate = today.toISOString()
    ignoreInvitation({
      variables: { id: invitationId, ignored_at: ignoreDate },
    })
  }

  return (
    <div
      className={clsx(
        classes.mainDiv,
        'grid py-2 flex-col items-center justify-center gap-4 mt-2 md:flex-row md:justify-between md:gap-2 grid-flow-row-dense grid-cols-3 grid-rows-1',
      )}
    >
      <div className="flex overflow-hidden relative col-span-2 gap-2 max-w-md">
        <Link href={`/be/${username}`} passHref legacyBehavior>
          <div>
            <Avatar
              objectFit="cover"
              layout="fill"
              className="inline object-cover rounded-full"
              profile={profile}
              alt="Profile image"
              size={12}
            />
          </div>
        </Link>
        <div
          className={clsx(
            classes.currentJobContainer,
            'flex flex-col content-center md:col-span-3',
          )}
        >
          <Link href={`/be/${username}`} passHref legacyBehavior>
            <div className="text-sm font-bold hover:cursor-pointer">
              {name.first_name} {name.last_name}{' '}
            </div>
          </Link>
          <div>
            <CurrentJob
              className="text-xs truncate"
              textColor="text-gray"
              experiences={currentJob?.experiences!}
              currentJob={currentJob?.currentJob!}
            />
          </div>
          <div className="text-xs text-gray-mid">
            {mutualConnections} Mutual Connection{mutualConnections != 1 && 's'}
          </div>
        </div>
      </div>

      {type === 'pending_connections' ? (
        <div className="flex col-span-1 gap-1 p-2">
          <button
            onClick={accept}
            className="py-1.5 px-3 font-bold text-white rounded-md md:text-xs bg-primary hover:bg-purple-mid"
          >
            Accept
          </button>
          <button
            onClick={ignore}
            className="py-1.5 px-3 font-bold text-white rounded-md md:text-xs bg-gray-dark hover:bg-purple-mid"
          >
            Ignore
          </button>
        </div>
      ) : (
        <div className="flex col-span-1 gap-1 p-2">
          <button
            onClick={accept}
            className="py-1 px-1 font-bold text-white rounded-lg md:text-sm bg-primary hover:bg-purple-mid"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 fill-[#fff]"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <button
            onClick={ignore}
            className="py-1 px-1 font-bold text-white rounded-lg md:text-sm bg-gray-dark hover:bg-purple-mid"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 fill-[#fff]"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      )}
    </div>
  )
}

export default InvitationCard
