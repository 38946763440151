import { GetFullProfile_profiles } from 'operations/GetFullProfile'
import { GetProfileByOwnerId_profiles } from 'operations/GetProfileByOwnerId'

import CreateBusinessPage from '@/components/home/business_page/create_business_page'
import Link from 'next/link'
import { FunctionComponent } from 'react-transition-group/node_modules/@types/react'

import useBusinessProfiles from 'components/hooks/useBusinessProfiles'
import { Box, Text } from 'components/ui'

interface IMyBusinessPages {
  profile: GetProfileByOwnerId_profiles | GetFullProfile_profiles | undefined
  isOwner: boolean
}

const MyBusinessPages: FunctionComponent<IMyBusinessPages> = ({
  profile,
  isOwner,
}) => {
  const businessProfiles = useBusinessProfiles(profile?.id)

  return (
    <Box title="My Business Pages" className="z-10">
      <div className="flex flex-col">
        {businessProfiles.map((businessProfile) => (
          <div
            key={businessProfile.username}
            className="py-2 w-full text-center rounded cursor-pointer hover:bg-gray-200"
          >
            <Link
              href={`/page/org/${businessProfile.username}`}
              passHref
              legacyBehavior
            >
              <Text variant="subheading" capitalize>
                {businessProfile.name}
              </Text>
            </Link>
          </div>
        ))}
      </div>
      {isOwner && <CreateBusinessPage />}
    </Box>
  )
}

export default MyBusinessPages
