import { useRouter } from 'next/router'

import { PermissionWrapper } from 'components/common/hocs'
import { Box, Button } from 'components/ui'

const CreateBusinessPage = () => {
  const router = useRouter()
  const handleCreateBusinessPage = () => {
    router.push('/page/create')
  }
  return (
    <PermissionWrapper
      action="CreateBusinessPage"
      behavior="Disable"
      tooltip="You don't have permission to create a business page yet. Your membership application is still being reviewed. Once approved you will have full access. In the meantime you can complete your profile and view different areas of Belongly."
    >
      <Button
        onClick={handleCreateBusinessPage}
        variant="secondary"
        className="w-full"
      >
        Create Business Page
      </Button>
    </PermissionWrapper>
  )
}

export default CreateBusinessPage
