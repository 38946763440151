import { Q_GET_FULL_PROFILE } from 'lib/queries/profiles'

import { gql, useMutation } from '@apollo/client'
import { useState } from 'react'

import useProfile from 'components/hooks/useProfile'
import { ComboboxOption, RadioGroupButtons, Text } from 'components/ui'

import { Availabilities } from '../user_basic_details/availability_badge'

const U_AVAILABILITY = gql`
  mutation UpdateAvailability($profileId: Int!, $value: String!) {
    update_profiles(
      where: { id: { _eq: $profileId } }
      _set: { availability: $value }
    ) {
      affected_rows
    }
  }
`

const AvailabilityToggle = ({
  hasLabel = false,
  availabilityValue,
  direction = 'vertical',
}: {
  hasLabel?: boolean
  availabilityValue: Availabilities
  direction?: 'horizontal' | 'vertical'
}) => {
  const { profile: ownerProfile } = useProfile()

  const options = [
    { title: 'Accepting New Clients', value: Availabilities.accepting },
    {
      title: 'Not Accepting New Clients',
      value: Availabilities.notAccepting,
    },
    { title: 'Waitlist', value: Availabilities.waitlist },
  ]

  const [profileStatus, setProfileStatus] = useState<ComboboxOption>(
    options.find((option) => option.value === availabilityValue) || {
      title: '',
      value: '',
    },
  )
  const [updateAvailability] = useMutation(U_AVAILABILITY, {
    refetchQueries: [Q_GET_FULL_PROFILE, 'ProfileIdGetProfile'],
  })

  const handleChange = (selected: string) => {
    const newOption = options.find(({ title }) => title === selected) || {
      title: '',
      value: '',
    }
    setProfileStatus(newOption)
    updateAvailability({
      variables: {
        profileId: ownerProfile?.id,
        value: newOption.value,
      },
    })
  }

  return (
    <div className="flex flex-col gap-2 w-full text-center">
      {hasLabel && (
        <Text variant="body" size="base">
          My Status
        </Text>
      )}
      <RadioGroupButtons
        options={options.map(({ title }) => title)}
        onSelect={handleChange}
        value={profileStatus.title}
        vertical={direction === 'vertical'}
        fullWidth
      />
    </div>
  )

  /* return (
    <div className="flex flex-col gap-2 w-full">
      {hasLabel && <Text weight="semibold">Your Status:</Text>}
      <Combobox
        contentEditable={false}
        onSelectItem={(option) => {
          if (!Array.isArray(option)) handleChange(option)
        }}
        selectedItem={profileStatus}
        hasArrowIcon
        options={options}
        placeholder="Choose your availability"
      />
    </div>
  ) */

  /* const radioStyle =
    'px-1 py-1.5 w-1/2 hover:bg-primary hover:text-white rounded-md text-center w-full select-none hover:cursor-pointer'
  return (
    <RadioGroup
      value={profileStatus}
      onChange={handleChange}
      className="flex flex-col"
      name="status"
    >
      {hasLabel && <RadioGroup.Label>Your status:</RadioGroup.Label>}
      <div className="flex py-2 px-1 text-xs rounded-md bg-gray-light">
        <RadioGroup.Option
          className={({ checked }) =>
            clsx(radioStyle, checked ? 'bg-primary text-white' : '')
          }
          value={Availabilities.accepting}
        >
          Accepting new clients
        </RadioGroup.Option>
        <RadioGroup.Option
          className={({ checked }) =>
            clsx(radioStyle, checked ? 'bg-primary text-white' : '')
          }
          value={Availabilities.notAccepting}
        >
          Not Accepting new clients
        </RadioGroup.Option>
        <RadioGroup.Option
          className={({ checked }) =>
            clsx(radioStyle, checked ? 'bg-primary text-white' : '')
          }
          value={Availabilities.waitlist}
        >
          Waitlist
        </RadioGroup.Option>
      </div>
    </RadioGroup>
  ) */
}

export default AvailabilityToggle
