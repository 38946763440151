import type { GetYouMayKnowProfiles_profiles } from 'operations/GetYouMayKnowProfiles'

import Link from 'next/link'
import { FunctionComponent } from 'react'

import useMutualConnections from 'components/hooks/useMutualConnections'
import { ProfilePickProps } from 'components/profile/profile.interface'
import { Avatar, Text } from 'components/ui'

import ConnectButton from './connect_button'

interface ListProfileCardProps {
  type?: string
  profile: Pick<
    ProfilePickProps,
    'avatar' | 'first_name' | 'last_name' | 'id' | 'username'
  >
}

const ListProfileCard: FunctionComponent<ListProfileCardProps> = ({
  type,
  profile,
}) => {
  const { mutualConnections, ownerId } = useMutualConnections(profile?.id)

  return (
    <div className="flex flex-col py-2 px-4">
      <a className="flex flex-col gap-1">
        <div className="flex flex-row gap-2 items-center m-1 space-x-2">
          <div className="flex items-center hover:underline flex-col-2">
            <Link href={`/be/${profile?.username}`} passHref legacyBehavior>
              <div>
                <Avatar
                  profile={profile}
                  layout="fill"
                  className="inline object-cover rounded-full"
                  alt="Profile image"
                  size={16}
                />
              </div>
            </Link>
          </div>
          <div className="flex md:col-span-3">
            <div className="flex flex-col gap-1">
              <Link href={`/be/${profile?.username}`} passHref legacyBehavior>
                <Text
                  variant="subheading"
                  className="cursor-pointer hover:underline text-[15px]"
                >
                  {profile?.first_name + ' ' + profile?.last_name || ''}
                </Text>
              </Link>
              <Text variant="small" className="text-[13px]">
                {mutualConnections} Mutual Connection
                {mutualConnections != 1 && 's'}
              </Text>
            </div>
          </div>
        </div>
        {type == 'connect' && (
          <div className="flex my-2 w-full">
            <ConnectButton
              fromProfileId={ownerId!}
              toProfileId={profile?.id}
              className="w-full"
            />
          </div>
        )}
      </a>
    </div>
  )
}

export default ListProfileCard
