import type { GetYouMayKnowProfiles } from 'operations/GetYouMayKnowProfiles'

import { gql, useQuery } from '@apollo/client'
import { FunctionComponent, useState } from 'react'

import { PermissionWrapper } from 'components/common/hocs'
import ManualInvitationModal from 'components/common/manual_invitation_modal'
import { Box, Button } from 'components/ui'

import ListProfileCard from '../common/connection_card'

const Q_GET_PROFILES = gql`
  query GetYouMayKnowProfiles($userId: bpchar) {
    profiles(
      where: {
        _and: [
          {
            _or: [
              { owner_id: { _neq: $userId } }
              { owner_id: { _is_null: true } }
            ]
          }
          {
            _not: {
              connections_from: { to_profile: { owner_id: { _eq: $userId } } }
            }
          }
        ]
      }
      order_by: { random: asc }
    ) {
      id
      first_name
      last_name
      avatar
      username
      experiences
      current_job_new
    }
  }
`

interface PeopleYouMayKnowProps {
  type?: string
  userId: string
}

const PeopleYouMayKnow: FunctionComponent<PeopleYouMayKnowProps> = ({
  type = 'connect',
  userId,
}) => {
  const { data } = useQuery<GetYouMayKnowProfiles>(Q_GET_PROFILES, {
    variables: { userId },
  })

  const [Disable, setDisable] = useState<boolean>(false)

  return (
    <>
      <section className="people-you-may-know">
        {type === 'connect' && (
          <Box title="People you may know" isSidebar>
            {data?.profiles.slice(0, 3)?.map((p, i) => (
              <div className="relative" key={i}>
                <ListProfileCard type={type} profile={p} />
              </div>
            ))}
            <PermissionWrapper
              action="InviteColleagues"
              behavior="Disable"
              tooltip="You don't have the permissions to invite colleagues yet. Your membership application is still being reviewed. Once approved you will have full access. In the meantime you can complete your profile and view different areas of Belongly."
            >
              <Button
                className="w-full"
                variant="secondary"
                onClick={() => {
                  setDisable(true)
                }}
              >
                Invite Colleagues
              </Button>
            </PermissionWrapper>
          </Box>
        )}
      </section>
      <ManualInvitationModal
        isOpen={Disable}
        onClose={() => {
          setDisable(false)
        }}
      />
    </>
  )
}

export default PeopleYouMayKnow
