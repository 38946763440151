import {
  GetBusinessProfiles,
  GetBusinessProfilesVariables,
} from 'operations/GetBusinessProfiles'

import { Q_GET_BUSINESS_PROFILES } from 'lib/queries/business_profiles'

import { useQuery } from '@apollo/client'

function useBusinessProfiles(profileId?: number) {
  const { data: businessProfilesData } = useQuery<
    GetBusinessProfiles,
    GetBusinessProfilesVariables
  >(Q_GET_BUSINESS_PROFILES, {
    variables: {
      profileId: profileId || -1,
    },
    skip: !profileId,
  })

  const businessProfilesIAmCreator =
    businessProfilesData?.businessProfilesIAmCreator || []
  const businessProfilesIAmAdmin =
    businessProfilesData?.businessProfilesIAmAdmin || []

  const businessProfiles = [
    ...businessProfilesIAmCreator,
    ...businessProfilesIAmAdmin,
  ]

  return businessProfiles
}
export default useBusinessProfiles
